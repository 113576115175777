import React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"

const Success = () => (
  <>
    <Helmet bodyAttributes={{ class: "info short" }} />
    <Layout>
      <Container className="info-contain">
        <Row>
          <Col>
            <h1 className="page-title">Thanks!</h1>
            <p className="description">I'll get back to you soon!</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </>
)

export default Success
